import { createSlice } from '@reduxjs/toolkit'

const errorSlice = createSlice({
    name: 'error',
    initialState: {
      value: ''
    },
    reducers: {
      writeError: (state, err) => {
        state.value = err.payload
      },
    }
})

export const {writeError} = errorSlice.actions;
export default errorSlice.reducer