import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { fetchUserInfo } from '../services/auth';


const Profile = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const getUserInfo = async () => {
      const searchParams = new URLSearchParams(location.search);
      const provider = searchParams.get('provider');
      const accessToken = searchParams.get('accessToken');

      if (!provider || !accessToken) {
        setError('Missing provider or access token');
        setLoading(false);
        return;
      }

      try {
        const data = await fetchUserInfo(provider, accessToken);
        setUserInfo(data);
      } catch (err) {
        setError(err.message || 'An error occurred while fetching user information');
      } finally {
        setLoading(false);
      }
    };

    getUserInfo();
  }, [location]);

  const handleShowUserInfo = () => {
    let keys = Object.keys(userInfo);

    return (
      <>
        {keys.map((key => (
          <p>{userInfo[key]}</p>
        )))}
      </>
    )
  }

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <h1>Profile</h1>
      {userInfo ? (
        <div>
          {handleShowUserInfo()}
          {/* Add more user information fields as needed */}
        </div>
      ) : (
        <p>No user information available</p>
      )}
    </div>
  );
};

export default Profile;