import React, {useEffect, useState} from 'react';
import '../styles/login.css'
import { fetchProviders } from '../services/provider';
import { loginProvider } from '../services/auth'
import { useDispatch } from 'react-redux';
import { writeError } from '../store/errorSlice';
import { useNavigate } from "react-router-dom";
import { useSearchParams } from 'react-router-dom';

const showPartner = process.env.REACT_APP_SHOW_PARTNERS == 'true' ? true : false

const Login = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [providers, setProviders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('')

  const handleEntrar = async () => {
    setLoading(true)
    try {
      let environment = searchParams.get("origem")

      const response = await loginProvider(email, environment)
      if(response.status < 500) {
        if(response.status == 200 && response?.data) {
          window.location.href = response.data
        } else if (response.status == 404) {
          window.location.href = response.response.data.url
        } else if (response.status == 422) {
          setLoading(false);
          setError(response.response.data.error ? response.response.data.error : response.message)
        } else {
          setLoading(false);
          dispatch(writeError(response.response.data.error ? response.response.data.error : response.message))
          navigate("/error");
        }
      } else {
        setLoading(false);
        dispatch(writeError(response.response.data.error ? response.response.data.error : response.message))
        navigate("/error");
      }
    } catch (err) {
      setLoading(false);
      dispatch(writeError(err.message))
      navigate("/error");
    } finally {
      setEmail('')
    }
  }

  const initialize = async () => {
    setLoading(true)
    try {
      const data = await fetchProviders();
      if(!data || data.message) {
        dispatch(writeError(data.message))
        navigate("/error");
      } else {
        setProviders(data);
      }
      
    } catch (err) {
      dispatch(writeError(err.message))
      navigate("/error");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if(showPartner) {
      initialize()
    } else {
      setLoading(false)
    }
  }, [])

  return (
    <div className='login-container w-100 d-flex justify-content-center align-items-center'>
      <div className='w-100 d-flex justify-content-center align-items-center bg-white' style={{ height: '98%' }}>
        <div className='container'>

          {loading ? (
            <div className='w-100 d-flex justify-content-center align-items-center'>
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div> 
          ) : (
            <div className='row justify-content-center'>
              <div className='col-12 col-md-8'>
                <div className='d-flex justify-content-center align-items-center'>
                  <img src='/logo-gep.png' style={{ maxWidth: '80%', height: 'auto' }}/>
                </div>

                <div className='row justify-content-center' style={{marginTop: '10%', marginBottom: '10%'}}>
                  <div className='col-12 col-md-6'>
                    <h1 className='text-center'>Olá</h1>
                    <div className='text-secondary text-center'>
                      Seja bem vindo. Informe seu login e clique no botão Continuar.
                    </div>
                  </div>
                </div>

                <div className='row justify-content-center'style={{ marginBottom: '5%'}}>
                  <div className='col-12 col-md-6'>
                    <div className="form-group">
                      <h4 className='mb-2'>Entrar</h4>
                      <input type="email" onChange={(e) => {
                        setError('')
                        setEmail(e.target.value)
                      }}
                      className="form-control rounded-0 border-0 border-bottom border-primary" placeholder="Email"
                      />
                      {error && (
                        <small className='text-danger'>{error}</small>
                      )}
                    </div>
                  </div>
                </div>

                <div className='row justify-content-center'style={{ marginBottom: '10%'}}>
                  <div className='col-12 col-md-6 d-flex justify-content-end'>
                    <button className="btn btn-primary text-white " onClick={handleEntrar}>
                      Continuar
                    </button>
                  </div>
                </div>

                {showPartner && (
                  <div className='row justify-content-center'>
                    <div className='col-12'>
                      <div className='fw-bold text-center' style={{ color: '#f06e08' }}>Nossos parceiros:</div>
                      <div className='d-flex justify-content-center row mt-1 p-3'>
                        {providers.map((x) => (
                          <div className='col-2 justify-content-center d-flex'>
                            <div className="btn-login text-white p-0"
                              data-toggle="tooltip" title={x.name}
                            >
                              <img
                                src={`data:image/png;base64, ${x.logoBase64}`} alt={x.name}
                                style={{ maxHeight: 70, maxWidth: '100%', minHeight: 50, minWidth: 50 }}
                              />
                            </div>
                          </div>
                          
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          
        </div>
      </div>
    </div>
  );
};

export default Login;