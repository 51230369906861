import React, {useEffect, useMemo, useState } from 'react';
import '../styles/error.css'
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';

function useQuery() {
    const { search } = useLocation();
    
    return useMemo(() => new URLSearchParams(search), [search]);
}

const ErrorPage = () => {
    let query = useQuery();
    const errorState = useSelector((state) => state.error.value)
    const navigate = useNavigate()
    const [error, setError] = useState('')
    const [typeError, setTypeError] = useState('')

    const retornar = () => {
        navigate('/')
    }

    useEffect(() => {
        let errorQuery = query.get("message")
        let errorType = query.get("type")

        if(errorQuery) {
            setError(errorQuery)
        } else {
            setError(errorState)
        }
        setTypeError(errorType ? errorType : '')
    }, [])

    return (
        <div className='error-page w-100 d-flex justify-content-center align-items-center'>
            <div className='d-flex justify-content-center align-items-center bg-white h-100' style={{ width: '98%', borderBottomLeftRadius: 24, borderBottomRightRadius: 24 }}>
                <div className='error-container'>
                    <div className='container'>
                        <div className='col-4 col-md-4 col-lg-6'>
                            <h1 className='error-color'>Ooops...</h1>
                            <div className='error-color mt-1 mb-4' style={{ color: typeError == 'error' ? 'red' : 'auto' }}>{error}</div>
                            <button className="btn text-white" style={{ backgroundColor: '#13326a' }} onClick={retornar}>Tentar novamente</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ErrorPage;