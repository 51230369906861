import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL

// Criando uma instância personalizada do axios com headers e opções
const api = axios.create({
});


export const fetchUserInfo = async (provider, accessToken) => {
  try {
    const params = {
      provider: provider,
      accessToken: accessToken
    }
   
    const response = await api.post(`${apiUrl}/User/profile`, params);
    return response.data;
  } catch (error) {
    console.error("Error fetching user info:", error);
    throw error;
  }
};

export const loginProvider = async (provider, environment) => {
  try {
    debugger
    const response = await api.post(`${apiUrl}/OAuth/login`, {login: provider, environment: environment ? environment : ''});
    return response;
  } catch (error) {
    return error;
  }
};